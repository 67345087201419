<template>
  <v-card elevation="1">
    <v-form ref="form" v-model="valid" lazy-validation class="pt-4">
      <v-row class="px-6 pt-2">
        <v-col cols="4">
          <v-text-field
            v-model="formData.nome"
            placeholder="Digite o nome do produto"
            outlined
            dense
            :clearable="true"
            label="Nome do Produto"
            required
            :rules="rules.genericRules"
          >
          </v-text-field>

          <v-radio-group v-model="formData.origem" row class="mt-0">
            <template v-slot:label>
              <div>Produto prórprio:</div>
            </template>
            <v-radio label="Produto FQM" value="fqm"></v-radio>
            <v-radio label="Produto de parceiro" value="parceiro"></v-radio>
          </v-radio-group>

          <v-autocomplete
            v-if="formData.origem === 'fqm'"
            v-model="formData.linhasProdutosId"
            :items="listLinhas"
            hide-no-data
            hide-selected
            item-text="nome"
            item-value="id"
            label="Selecione a linha para o produto"
            placeholder="Selecione a linha do produto"
            outlined
            dense
            :clearable="true"
            required
            :rules="rules.selectRules"
          ></v-autocomplete>

          <v-autocomplete
            v-if="formData.origem === 'parceiro'"
            v-model="formData.empresasParceirasId"
            :items="listEmpresasParceiras"
            hide-no-data
            hide-selected
            item-text="nome"
            item-value="id"
            label="Selecione a empresa parceira"
            placeholder="Selecione um empresa parceira"
            outlined
            dense
            :clearable="true"
            required
            :rules="rules.selectRules"
          ></v-autocomplete>

          <v-autocomplete
            v-model="formData.aprovadorIc"
            :items="listMedicoAprovadores"
            hide-no-data
            hide-selected
            item-text="nome"
            item-value="id"
            label="Assessor Médico"
            placeholder="Selecione um aprovador"
            outlined
            dense
            :clearable="true"
            required
            :rules="rules.selectRules"
          ></v-autocomplete>

          <v-autocomplete
            v-model="formData.responsavelTreinamento"
            :items="listResponsavel"
            hide-no-data
            hide-selected
            item-text="nome"
            item-value="id"
            label="GEP Responsável"
            placeholder="Selecione o(a) GEP Responsável"
            outlined
            dense
            :clearable="true"
            required
            :rules="rules.selectRules"
          ></v-autocomplete>
        </v-col>
      </v-row>
    </v-form>
    <v-card-actions class="px-6 pb-4">
      <v-btn
        depressed
        color="fmq_gray"
        dark
        @click="send"
        :loading="loadingBtn"
        >{{ isEdit ? "Editar Produto" : "Criar Produto" }}</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { rules } from "@/utils/rules.js";
export default {
  name: "ProdutoFormulario",
  props: {
    loadingBtn: {
      type: Boolean,
      default: false,
    },
    listLinhas: {
      type: Array,
      required: true,
    },
    listMedicoAprovadores: {
      type: Array,
      required: true,
    },
    listResponsavel: {
      type: Array,
      required: true,
    },
    listEmpresasParceiras: {
      type: Array,
      required: true,
    },
    produto: {
      type: Object,
      default: () => ({})
    },
  },
  data: () => ({
    formData: {
      nome: null,
      origem: "fqm",
      linhasProdutosId: null,
      aprovadorIc: null,
      responsavelTreinamento: null,
      empresasParceirasId: null,
    },
    rules: rules,
    valid: true,
    isEdit: false,
  }),
  created() {
    if (this.$route.name === "ProdutoEditar") {
      this.formData = this.produto;
      this.isEdit = true;
    }
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    send() {
      if (this.formValid) this.$emit("send", this.formData);
    },
  },
  computed: {
    formValid() {
      return this.$refs.form.validate();
    },
  },
};
</script>

<style></style>
